.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.topContainer {
  padding: 1rem;
  padding-bottom: 0;
}

.bottomContainer {
  padding: 1rem;
  flex-grow: 1;
}

.hashtag {
  text-align: center;
  margin-bottom: 0.5rem;
}

.image {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 1rem;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.userName {
  flex-grow: 1;
}

.title {
  margin-bottom: 1rem;
}

.paginationLeft {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  margin-left: -10px;
}

.paginationRight {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  margin-right: -10px;
}
