.container {
  display: grid;
  grid-template-columns: 66.667% 33.334%;
  height: 100%;
}

.mediaWrapper {
  position: relative;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.contentContainer {
  padding: 2rem 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  scrollbar-width: none; /* Firefox 64 */
  -webkit-overflow-scrolling: touch;
}

.headingContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.hidden {
  visibility: hidden;
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 1rem;
}

.date {
  margin-left: auto;
}

.titleContainer {
  margin-bottom: 2rem;
}
