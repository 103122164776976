.background {
  width: 100%;
  line-height: 0;
  padding-bottom: 10px;
}

.image,
.skeleton {
  width: 100%;
  height: auto;
  min-height: 250px;
  object-fit: cover;
  overflow-x: visible;
}

.imageContainer::after {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 1) 90%
  );
}

.imageContainer {
  position: relative;
  margin-bottom: 1.5rem;
}

.contentContainer {
  z-index: 1;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 80px;
  right: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
}

.description {
  text-shadow: 2px 2px 2px #000;
  position: relative;
}

.background {
  background-color: black;
  width: 100%;
  line-height: 0;
}

.skeleton {
  min-height: 448px;
}

.button {
  width: 200px;
  padding: 0.5rem;
}

.buttonContainer {
  margin-top: 4px;
}

.toolboxTitle {
  object-fit: cover;
  margin-top: 0.5rem;
}

.description {
  --sizeMod: 2;
  width: 400px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.reducedArticleCard {
  width: 19.5rem;
  height: 17.75rem;
}

@media (min-width: 1024px) {
  .contentContainer {
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
    bottom: 80px;
  }

  .textMobileContainer {
    display: none;
  }

  .button {
    width: 250px;
  }

  .imageContainer {
    position: relative;
    margin-bottom: -1rem;
  }
}

@media (max-width: 1200px) {
  .contentContainer {
    justify-content: flex-start;
    top: 0;
  }
}

@media (min-width: 1440px) {
  .title {
    --sizeMod: 3;
  }

  .imageContainer {
    position: relative;
  }
}

@media (max-width: 340px) {
  .title {
    --sizeMod: 2.5;
  }

  .description {
    --sizeMod: 1.5;
    width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  .imageContainer {
    position: relative;
    margin-bottom: 1.5rem;
  }
}
